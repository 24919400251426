<template>
<div></div>
</template>
<script>
export default {
  name: 'Signout',
  mounted() {
      this.logout()
  },
  methods: {
    async logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login').catch((err) => {
          console.log(err)
        })
      })
    }
  },
}
</script>
